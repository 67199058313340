@font-face {
    font-family: Nunito;
    src: url('@assets/fonts/Nunito/Nunito-Regular.ttf');
}

@font-face {
    font-family: Montserrat;
    src: url('@assets/fonts/Montserrat/Montserrat-Regular.ttf');
}

@font-face {
    font-family: PlayfairDisplay;
    src: url('@assets/fonts/PlayfairDisplay/PlayfairDisplay-Regular.ttf');
}

@font-face {
    font-family: PTSansNarrow;
    src: url('@assets/fonts/PTSansNarrow/PTSansNarrow-Regular.ttf');
}

@font-face {
    font-family: Roboto;
    src: url('@assets/fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
    font-family: RubikBeastly;
    src: url('@assets/fonts/RubikBeastly/RubikBeastly-Regular.ttf');
}

@font-face {
    font-family: Gilroy;
    src: url('@assets/fonts/Gilroy/Gilroy-Regular.ttf');
}

@font-face {
    font-family: Gilroy;
    src: url('@assets/fonts/Gilroy/Gilroy-Black.ttf');
    font-weight: bold;
}
@font-face {
    font-family: Gilroy;
    src: url('@assets/fonts/Gilroy/Gilroy-Medium.ttf');
    font-weight: 500;
}