* {

    font-optical-sizing: auto;
}

header, .top-block, .left-block, .right-block{
font-family: "Nunito", sans-serif;
}

body {
    margin: 0;
    overflow-x: hidden !important;
    background-color: #edf0f5;
    color: #303e67;
    min-height: 100vh;
    letter-spacing: 0.1px;
    line-height: 1.3;
    position: relative;
    font-size: 14px;

}

p {
    line-height: 1.3;
}

a:hover {
    text-decoration: none;
}

hr {
    border: 0;
    height: 1px;
    background: #f1f5fa;
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #303e67;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.3;
    border-radius: 4px;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.waves-effect {
    position: relative;
    cursor: pointer;
    display: inline-block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.btn {
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;
    -webkit-box-shadow: 0 7px 14px 0 rgba(234, 240, 249, 0.5);
    box-shadow: 0 7px 14px 0 rgba(234, 240, 249, 0.5);
}

.btn:hover {
    color: #303e67;
    text-decoration: none;
}

.btn:hover {
    -webkit-box-shadow: 0 1px 2px 0 rgba(234, 240, 249, 0.5);
    box-shadow: 0 1px 2px 0 rgba(234, 240, 249, 0.5);
}

.btn-light {
    color: #1c2d41;
    background-color: #f1f5fa;
    border-color: #f1f5fa;
    -webkit-box-shadow: 0 7px 14px 0 rgba(182, 194, 228, 0.6);
    box-shadow: 0 7px 14px 0 rgba(182, 194, 228, 0.6);
}


.btn-light:hover {
    color: #1c2d41;
    background-color: #d5e1f0;
    border-color: #cbdaed;
}

.btn-danger {
    color: #fff;
    background-color: #f14b4b;
    border-color: #f14b4b;
    box-shadow: 0 7px 14px 0 rgba(241, 75, 75, 0.5);
}

.btn-danger:hover {
    color: #fff;
    background-color: #ee2828;
    border-color: #ed1c1c;
    box-shadow: none;
}

.btn-dark {
    color: #fff;
    background-color: #1c2d41;
    border-color: #1c2d41;
    -webkit-box-shadow: 0 7px 14px 0 rgba(28, 45, 65, 0.5);
    box-shadow: 0 7px 14px 0 rgba(28, 45, 65, 0.5);
}

.btn-dark:hover {
    color: #fff;
    background-color: #101a26;
    border-color: #0d141d;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn-primary {
    color: #fff;
    background-color: #1761fd;
    border-color: #1761fd;
    -webkit-box-shadow: 0 7px 14px 0 rgba(23, 97, 253, 0.5);
    box-shadow: 0 7px 14px 0 rgba(23, 97, 253, 0.5);
}

.right-block .btn-primary {
    color: #000;
    background-color: #fff;
    border-color: #edf0f5;
    box-shadow: none;
}

.btn-primary:hover {
    color: #fff;
    background-color: #024dec;
    border-color: #0249df;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn-green {
    color: #fff;
    background-color: #29b348;
    border: 1px solid #29b348;
    -webkit-box-shadow: 0 7px 14px 0 rgba(30, 202, 123, 0.5);
    box-shadow: 0 7px 14px 0 rgba(30, 202, 123, 0.5);
}

.btn-green:hover {
    color: #fff;
    background-color: #19a967;
    border-color: #179e60;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.color-red {
    color: #d60000;
}

.color-green {
    color: #1ab000;
}

header {
    display: grid;
    grid-template-columns: auto 1fr;
    background: #fff;
    min-height: 55px;
    position: relative;
    align-content: center;
    justify-content: space-between;
    padding: 0 30px;
    margin-bottom: 15px;
}

.button-header {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: end;
    align-items: center;
    gap: 50px
}

header img {
    width: 32px;
}

.slide-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 15px;
    padding: 0 15px;
    align-items: start;
}

.top-block {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 3;
    grid-column-end: 13;
    padding: 15px;
    background-color: #fff;
    display: grid;
    grid-template-columns: repeat(4, auto);
    justify-content: start;
}
.top-block .custom-file, .top-block .example-collapse-text{
    margin-left: 20px;
}

.menu-add-button {
    display: flex;
    gap: 8px;
}
.menu-add-slide {
    display: grid;
    grid-template-columns: repeat(4, auto);
    justify-content: start;
    gap: 8px;
    padding: 4px 4px;
    background-color: #edf0f5;
    border-radius: 4px;
    margin-left: 10px;
}
.menu-add-slide .btn{
    white-space: nowrap;
}

.left-block {
    grid-row-start: 1;
    grid-row-end: 4;
    grid-column-start: 1;
    grid-column-end: 3;
    background-color: #fff;
    display: grid;
    text-align: center;
    padding: 15px;
    gap: 30px;
    margin-bottom: 30px;
}

.center-block {
    font-size: calc(100vw/1200);
    grid-row-start: 2;
    grid-column-start: 3;
    grid-column-end: 11;
    position: sticky;
    top: 15px;

}

.center-block p {
    font-size: 14em;
}

.logo-slide {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 50px;
   }

.slide-zone {
    /*max-height: 100vh;*/
    width: 100%;
    aspect-ratio: 16 / 9;
    background-color: #fff;
    border: 1px solid;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.right-block {
    grid-row-start: 2;
    grid-column-start: 11;
    grid-column-end: 13;
    padding: 15px;
    background-color: #fff;
    margin-bottom: 30px;
}

.setup-slide {
    background-color: #fff;
}

.slide-thumb {
    aspect-ratio: 16 / 9;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
}

.num-slide {
    position: absolute;
    padding: 2px 3px;
    background-color: #fff;
    top: 0;
    left: 0;
    border-radius: 3px 0 0 0;
}

.delete-thumb {
    width: 18px;
    height: 18px;
    position: absolute;
    top: -9px;
    right: -9px;
    display: block;
    -webkit-user-select: none;
    user-select: none;
    background-color: rgb(230, 230, 230);
    border: 0 none;
    border-radius: 20px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.delete-thumb:hover {
    background-color: #d60000;
}

.delete-thumb:hover::after {
    color: #fff;
}

.delete-thumb::after {
    position: absolute;
    content: "x";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #000;
}

.thumb-caption {
    padding: 0 10px;
    word-break: break-all;
}

.card-s {
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 4px;
    box-shadow: 0px 0px 6px rgba(31, 30, 47, 0.3);
    background-color: #fff;
}

.active-th {
    box-shadow: 0px 0px 6px #1761fd;
}

.logo {
    display: flex;
    align-items: center;
    gap: 20px;
}

.logo img {
    width: 42px;
}

.link-copy {
    display: flex;
    align-items: center;
}

.link-copy>div {
    padding-top: 3px;
}

.link-copy span,
.link-copy a {
    padding: 0 5px 0 0;
}

.link-copy a {
    color: #1761fd;
}

.btn.copy img {
    width: 22px;
}

.btn.run img {
    width: 18px;
}

.btn.run,
.btn.copy {
    display: flex;
    padding: 3px;
    background-color: transparent;
}

.btn.run:hover,
.btn.copy:hover {
    cursor: pointer;
}

.avatar {
    border: 0 none;
    padding: 0;
    background-color: transparent;
}

.avatar img {
    width: 42px;
}

.avatar:hover {
    cursor: pointer;
}

.image-block {
    flex-grow: 1;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.content-slide {
    padding: 31em;
    height: calc(100% - 5px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.choise-img button {
    font-size: 3vw;
}

.img-and-voite {
    min-width: 50%;
    display: grid;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
}
.options-voite{ 
    background-color: #fff;
    padding: 15px;
    border-radius: 20px;
}
.options-voite p{
    font-size: 15px;
}

.content-slide.vertical {
    flex-direction: row;
}

.content-slide.vertical .image-block {
    flex-grow: 1;
    width: 100%;
    height:100%
}

.content-slide.vertical p {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.title-slide {
    margin-top: 0;
    line-height: 1.3;
    font-weight: 700;
    padding-right: 60px;
}

.vote-block {
    display: flex;
}

.vote-text {
    width: 40%;
    font-size: 13rem;
}

#chart {
    flex-grow: 1;
    width: 100%;
}

.comment-block {
    overflow: auto;
    background-color: #fff;
    padding: 5px;
    border-radius: 6px;
    height: 100%;
    outline: none;
}

.media {
    display: flex;
    align-items: flex-start;
    margin: 20px 0;
}
.media:first-child {
margin-top: 0;
}
.media:last-child {
    margin-bottom: 0;
    }

.chat-msg {
    padding: 14em;
    background-color: #f1f5fa;
    color: #303e67;
    display: inline-block;
    margin: 5px;
    border-radius: 25px;
}

.chat-msg p {
    margin: 0;
    font-weight: 700;
}

.msg-author {
    
    color: #000;
    font-size: 12em;
}
.msg-author u{
    color:red
}

.form-group {
    margin-top: 20px;
}

label {
    font-weight: 400;
    color: #656d9a;
    font-size: 14px;
    display: inline-block;
    margin-bottom: 0.5rem;
}

label.label {
    font-weight: 600;
    color: #656d9a;
    font-size: 16px;
}

textarea {
    overflow: auto;
    resize: vertical;
    box-sizing: border-box;
}

.form-control {
    display: block;
    width: 100%;
    padding: 5px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.8;
    color: #303e67;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e8ebf3;
    border-radius: 4px;
    box-sizing: border-box;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

textarea.form-control {
    height: auto;
}

.form-control:focus {
    color: #303e67;
    background-color: #fff;
    border-color: rgba(23, 97, 253, 0.5);
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(23, 97, 253, 0.25);
    box-shadow: 0 0 0 2px rgba(23, 97, 253, 0.25);
}

.custom-control-label {
    padding-top: 2px;
}

.checkbox label {
    display: inline-block;
    padding-left: 8px;
    position: relative;
    font-weight: normal;
}

.checkbox label::before {
    -o-transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    background-color: #fff;
    border-radius: 4px;
    border: 2px solid #a4abc5;
    content: "";
    display: inline-block;
    height: 14px;
    left: 0;
    top: 1px;
    margin-left: -18px;
    position: absolute;
    transition: 0.3s ease-in-out;
    width: 14px;
    outline: none !important;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 4px;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #1761fd;
    background-color: #1761fd;
}

.custom-control-label::after {
    position: absolute;
    top: 0.2605rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%;
}

.checkbox label::after {
    color: #f1f5fa;
    display: inline-block;
    font-size: 11px;
    height: 18px;
    left: 0;
    margin-left: -18px;
    padding-left: 3px;
    padding-top: 2px;
    position: absolute;
    width: 18px;
}

.checkbox input[type="checkbox"]:checked+label::after {
    content: "";
    position: absolute;
    top: 3px;
    left: 6px;
    display: table;
    width: 2px;
    height: 6px;
    border: 2px solid #1c2d41;
    border-top-width: 0;
    border-left-width: 0;
    transform: rotate(45deg);
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    border-color: #fff;
}

.checkbox input[type="checkbox"] {
    cursor: pointer;
    opacity: 0;
    z-index: 1;
    outline: none !important;
}

.checkbox input[type="checkbox"]:disabled+label::before {
    opacity: 0.5;
}

.custom-file {
    position: relative;
    /*display: inline-block;*/
    width: 100%;
}

.custom-file .ps {
    position: relative;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    margin: 0;
    opacity: 0;
    line-height: 26px;
}

.custom-file-label {
    /*position: absolute;*/
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    padding: 5px 10px;
    font-weight: 400;
    line-height: 1.8;
    color: #303e67;
    background-color: #fff;
    border: 1px solid #e8ebf3;
    border-radius: 6px;

}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    padding: 5px 10px;
    line-height: 1.8;
    color: #303e67;
    content: "Обзор";
    background-color: #f1f5fa;
    border-radius: 6px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.name-file {
    font-size: 12px;
    margin-top: 5px;
}

.control-slide {
width: 100%;
    position: absolute;
    bottom: 5px;
    left: 5px;
    display: flex;
    gap: 8px;
    transition: opacity .5s ease;
}
.control-slide .right{
    display: flex;
    gap: 8px;
    margin-left:auto; 
}


.control-slide i {
    display: block;
    width: 30px;
    height: 30px;
}

.control-slide button {
    opacity: .2;
    background-color: transparent;
    border: 0 none;
    padding: 0;
}

.control-slide button:hover {
    opacity: 1;
    transition: opacity .5s ease;
}

.prev-s {
    background: url('@assets/images/arrow.svg');
    transform: rotate(180deg);
}

.next-s {
    background: url('@assets/images/arrow.svg');
}
.resault-s {
    background: url('@assets/images/resault.svg');
}
.menu-s {
    background: url('@assets/images/menu.svg');
}

.compress-s {
    background: url('@assets/images/compress.svg');
}
.moder-s {
    background: url('@assets/images/moder-on.svg');
}

.expand-s {
    background: url('@assets/images/expand.svg');
}
.download-s {
    background: url('@assets/images/download.svg');
}

.stop-s {
    background: url('@assets/images/stop.svg');
}
.clean-s {
    background: url('@assets/images/clean.svg');
}

.member {
    opacity: .2;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    background-color: #fff;
    border-radius: 12px;
}
.download-text {
    font-size: 12px;
    margin-left: 10px;
    text-align: left;
    line-height: 0.9;
}
.download-btn{
    display: flex;
    align-items: center;
}
.member:hover {
    opacity: 1;
}
.icon-th {
    width: 36px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 25px;
}

.label-rb {
    font-size: 14px;
}

.space-between {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.style-font label {
    margin-left: 5px;
}

.color-change {
    width: 28px;
    height: 28px;
    padding: 0;
}

.color-change::after {
    display: none;
}

.vote-edit {
    margin-left: 0;
    padding-left: 2px;
}

.vote-edit {
    list-style-type: decimal;
}

.form-control {
    display: block;
    width: 100%;
    padding: 5px 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    color: #303e67;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e8ebf3;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.dropdown-button-div button {
    height: 100%;
}

.dropdown-button-div button::after {
    margin-left: 11px;
    position: relative;
    top: 3px;
}

/*.apexcharts-data-labels text {
    transform: translateY(20px);
}*/

.options-voite .btn-voite {
    box-shadow: none;
    margin-bottom: 1.5em;
    text-align: left;
    border: 0 none;
    border-radius: 4px;
    margin-left: 5px;
    font-size: 18px;
    color: #fff;
    padding: 8px 12px;
}

.text-align .btn:hover img {
    filter: brightness(3);

}


.text-align .btn img {
    padding-right: 5px;
}

.text-align .btn::after {
    transform: translateX(-5px);

}

.text-align .dropdown-menu {
    min-width: auto;
}

.text-align .dropdown-menu img {
    width: 15px;
}

.custom-file input {
    display: none;
}

.custom-file label {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.caption-room-page {
    font-size: 36px;
    text-align: center;
    margin: 20px 0;
}

.start-pres {
    text-align: center;
}
.start-pres.one{
    display: flex;
    gap:25px;
    justify-content: center;
}

@media (orientation: landscape) {
    .sz-fullscreen {
        aspect-ratio: auto;
        width: 100vw;
        height: 100vh;
    }


}

@media (orientation: portrait) {
    .comment-block, .opinion {
        margin-bottom: 30px;
    }
    .comment-input {
        position: absolute;
        bottom: -50px;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 0 20px;
    }
    .options-voite {
        position: absolute;
        top: calc(100% + 10px);
        width: 90%;
    }
    .options-voite p{
        font-size: 20px;
    }
    .options-voite .btn-voite{
        font-size: 17px;
    }


}
.qr-code-object {
    height: 100%;
    max-height: 70%;
    text-align: center;
    margin-bottom: 53em;
    display: flex;
    justify-content: center;
}
.qr-code-object .cover{
    aspect-ratio: 1/1;
    position: relative;
    max-height: 100%;

}
.qr-code-object svg{
    aspect-ratio: 1 / 1;
    max-height: 100% !important;
    width: 100%;
    padding: 20em;
    background-color: #fff;
    align-self: flex-start;
    object-fit: contain;
}

.input-comment, .content-slide .btn {
    font-size: 18em;
    margin: 0 15px 0 0;
}

.auth-page {
    max-width: 460px;
    position: relative;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 15px;
}
.align-self-center{
    align-self: center !important;
}
.vh-100{
    height: 100vh !important;
}
.pres-list{
    list-style: none;
    
}
.pres-list li {
    padding: 15px 0;
    display: flex;
    column-gap: 25px;
    flex-direction: row;
    align-items: center;
   border-bottom: 1px solid #ccc;
    flex-wrap: nowrap;
    justify-content: space-between;
}
.pres-list a{
    font-size: 18px;
}
.pres-list .btn-danger {
    font-size: 10px;
    padding: 4px 8px;
    margin-left: 10px;
    box-shadow: 0 2px 5px 0 rgba(241, 75, 75, 0.3);
}
.user-info {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 15px;
}
.flex{display: flex;}
.wh-100pr{
    width:100%;
}
.content-slide textarea{
    width:100%;
}
.btn-copy-upr{
    margin-left: 25px;
}
.options-voite .btn-voite[disabled] {
    opacity: 0.3;
}
.msg-author {
    font-size: 1.5vw;
}
.chat-msg p {
    font-size: 3vw;
}

@media (max-width: 767px) {
    .logo-slide {
        width: 25px;
       }
    .comment-input .btn, .comment-input input{
        font-size: 14px;
    }
    /*.comment-block {
        margin-bottom: 30px;
    }*/
    .content-slide p {
       margin-bottom: 10px;
    }

    .chat-msg p {
        margin: 0;
    }  
    .chat-msg {
        margin: 0;
        border-radius: 6px;
        margin-right: 20px;
    }
   
    .media {
        display: flex;
        align-items: flex-start;
        margin: 5px 0;
    }
}

.container-index{
    max-width: 700px;
    width: 100%;
    padding: 40px 25px;
    margin: 0 auto;
    text-align: center;
}
.index-logo{
    width: 80px;
    margin-bottom: 25px;
}
.index-btn {
    display: flex;
    margin: 25px 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 15px;
    column-gap: 25px;
}
.index-btn a{
    font-size: 19px;

}
.container-index p{
    font-size: 17px;
}
.container-index h1{
    font-weight: bold;
    font-size: 25px;
}
footer {
    margin-top: 100px;
}
.container-index footer p{
    font-size: 13px;
}
.edit-buttons {
    display: flex;
    column-gap: 10px;
    flex-direction: row;
    flex-wrap: nowrap;
}
.rename-icon, .delete-icon, .copyy-icon{
    width: 22px;
    height: 22px;
    background-size: contain;
    border:0 none;
    background-repeat: no-repeat;
    background-color: transparent;
}
.rename-icon{
    background-image: url('@assets/images/rename.svg');
}
.copyy-icon{
    background-image: url('@assets/images/copyy.svg');
}
.delete-icon{
    background-image: url('@assets/images/delete.svg');
}
.copyy-icon.slide-thumb {
    position: absolute;
    left: -10px;
    bottom: -10px;
}

.control-msg {
    display: flex;
    padding: 10px;
    background-color: #f1f5fa;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 6px;
}
.control-msg p{
    margin-bottom: 5px;
}
.control-text{
    width: 100%;
}

.control-btn {
    min-width: 70px;
    display: flex;
    justify-content: space-between;
}
.control-btn .btn{
    padding: 5px 11px;
}
.choise-img {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
}
.choise-img button div{
    position: absolute;
    right: 0;
    top:0;
    font-size: 15px;
    padding: 3px;
    background-color: #fff;
}

.choise-img button{
    padding: 20px;
    margin: 20px;
    background-color: #f0f0f0;
    border: 10px solid #f0f0f0;
    position: relative;
}
.choise-img .start:hover{
    border-color: #dbdbdb;
}
.choise-img .selected{
    border-color: #4286f5;
}
.choise-img .true{
    border-color: #00b850;

}
.choise-img .false{
    border-color: #900303;

}
.choise-img img{
    max-width: 90%;
    max-height: 900%;
}
.opinion{
    font-size: 50px;
    height: 100%;
}
@media (max-width: 767px) {
    .opinion{
        font-size: 17px;
       
    }
    .choise-img button {
        padding: 5px;
        margin: 5px;
        border: 5px solid #f0f0f0;
    }
}
.cloud-word{
    background-color: #fff;
    border-radius: 20px;
}


.text-block{
    flex-grow:1;
}


